import React from "react";
import { string, bool } from "prop-types";
import { navigate } from "gatsby";
import CareersImage from "../../../components/ImageQuerys/CareersImages";
import Title from "../Title";

// const pathClass = "w-[50px] h-[50px]";

const ProductCardBlueCircle = ({
  className,
  title,
  linkTo,
  imageName,
  smallFont,
  darkenImage,
  openInNewTab,
}) => {
  const onClick = () =>
    openInNewTab ? window.open(linkTo, "_blank").focus() : navigate(linkTo);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`
        product-card
        relative cursor-pointer
        w-full h-auto overflow-hidden
        rounded-lg z-1 group
        ${className}
    `}
      onClick={onClick}
      data-href={linkTo}
    >
      <div className="relative">
        <CareersImage imageName={imageName} className="w-full h-full" />
        {darkenImage && (
          <div className="w-full h-full bg-black absolute left-0 top-0 opacity-30 z-1 rounded-lg" />
        )}
      </div>
      <div className="absolute left-8 bottom-8 flex flex-col start z-1">
        {!smallFont && (
          <Title level={3} className="text-white max-w-80-percent">
            {title}
          </Title>
        )}

        {smallFont && (
          <div className="text-h4 text-white font-normal font-400 text-[24px] leading-[31px] m-0">
            {title}
          </div>
        )}

        <div
          className={`
            w-16 rounded-full flex justify-center
            bg-transperant z-1
            border-none
            min-w-[220px]
            h-[30px]
            transition-all
            flex justify-start
            pt-[10px]
         `}
        >
          {/* <img className="py-2.5 px-6" alt="arrow right" src={arrow} /> */}
          {/* <svg
            width="37"
            height="33"
            viewBox="0 0 37 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[30px] h-[30px]"
          >
            <path
              d="M15.6667 6.33331H1"
              stroke="#090D12"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.332 1L15.6654 6.33333L10.332 11.6667"
              stroke="#090D12"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1048 1112"
            fill="white"
            width="100%"
            height="100%"
            className="w-[50px] h-[50px]"
          >
            <path
              className="w-[50px] h-[50px]"
              d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
            />
          </svg>
          <div className="min-w-[170px]" />
        </div>
      </div>

      <div
        className={`
        product-card--hover-circle
        absolute rounded-full bg-[#1769FF] z-0
      `}
      />

      <style jsx>{`
        .product-card {
          max-width: 320px;
          max-height: 380px;
        }

        .product-card--hover-circle {
          width: 484px;
          height: 484px;
          bottom: 0;
          left: 0;
          transition: 0.5s ease-out;
          transform: translate(-100%, 100%);
        }

        .product-card:hover .product-card--hover-circle {
          transition: 0.5s ease-out;
          transform: translate(-30%, 63%);
        }

        .product-card:hover svg path {
          stroke: white;
        }

        @media (min-width: 350px) {
          .product-card {
            margin-bottom: 30px;
          }
        }
        @media (min-width: 1280px) {
          .product-card {
            margin-bottom: 0;
          }
        }
      `}</style>
    </div>
  );
};

ProductCardBlueCircle.propTypes = {
  className: string,
  title: string,
  linkTo: string,
  imageName: string,
  smallFont: bool,
  darkenImage: bool,
  openInNewTab: bool,
};

ProductCardBlueCircle.defaultProps = {
  className: "",
  title: "",
  linkTo: "",
  imageName: "",
  smallFont: true,
  darkenImage: false,
  openInNewTab: false,
};

export default ProductCardBlueCircle;
