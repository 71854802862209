import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import Image from "../../components/ImageQuerys/RandomImages";
import { careersHero, jobOppenings } from "../../data/careers-data";
import {
  getAllDepartments,
  getSalesDepartments,
  getCorporateDepartments,
  fetchSome,
} from "../../api/greenhouse-api";
import CareersDisplay from "../../components/Careers/CareersDisplay";
import Subverticals from "../../components/SubVerticals/Subverticals";
import Section from "../../components-v2/Base/Section";
import Heading from "../../components-v2/Base/HeadingBuilder";
import ProductCardBlueCircle from "../../components-v2/Base/ProductCardBlueCircle";

import ogImage from "../../images/global_assets/og-image.png";

const heroBg = "careers-hero.png";
const mobileBg = "mobile-careers.png";
const img = "gptw-badges2.png";
const bg = "random-bg-blur.png";

const productsWithLinks = [
  {
    title: "Corporate",
    linkTo: "/careers/corporate/",
    imageName: "careers-corporate-jobs.png",
  },
  {
    title: "Sales",
    linkTo: "/careers/sales/",
    imageName: "careers-sales-jobs.png",
  },
  {
    title: "Product & Engineering",
    linkTo: "/careers/product/",
    imageName: "engineering-jobs.png",
  },
];

const Careers = () => {
  const [departments, setDepartments] = useState({
    sales: [],
    product: [],
    corp: [],
  });
  useEffect(() => {
    getAllDepartments()
      .then((areas) => {
        const prefilter = areas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          product: prefilter,
        }));
        return getSalesDepartments();
      })
      .then((salesareas) => {
        const prefilter = salesareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          sales: prefilter,
        }));
        return getCorporateDepartments();
      })
      .then((corpareas) => {
        const prefilter = corpareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          corp: prefilter,
        }));
      });
  }, []);

  return (
    <Layout>
      <SEO
        title="Careers & Job Culture at SpotOn Point of Sale | SpotOn "
        description="Find a work family not a normal job. We help our employees with growth and team building, for one common goal. Read reviews & join the #SpotOnFamily now."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/careers/"
      />

      <Section className="mt-20 md:mt-32 z-8">
        <Heading
          level={1}
          injectionType={2}
          withBlueSymbol
          className="max-w-[645px] mx-auto text-center"
        >
          We never stop looking for talented people
        </Heading>

        <p className="text-black-300 text-[20px] text-center">
          See our exciting career opportunities and apply today.
        </p>

        <div
          className={`
          mt-10 md:mt-[100px]
          flex flex-col md:flex-row flex-wrap
          justify-center items-center md:items-start
          gap-10 xl:gap-20
        `}
        >
          <ProductCardBlueCircle {...productsWithLinks[0]} />
          <ProductCardBlueCircle
            className="xl:mt-[-50px]"
            {...productsWithLinks[1]}
          />
          <ProductCardBlueCircle {...productsWithLinks[2]} />
        </div>
      </Section>

      <div
        className={`
        max-w-1440 max-h-[200px] md:max-h-auto border-box mx-8 lg:mx-0 relative z-0
        mt-10 md:mt-20 mb-40
      `}
      >
        <p
          className="text-center text-black-300 text-[16px] z-20 hidden md:block"
          style={{ zIndex: 20 }}
        >
          Proud to be recognized as a great place to work.
        </p>
        <Image imageName={img} className="mx-auto max-w-[372px] z-20" />
        <Image
          imageName={bg}
          className="w-full absolute translate-y-[-200px] lg:translate-y-[-500px]"
        />
      </div>
      {/* <CareersDisplay */}
      {/*  careersDepartments={departments} */}
      {/*  openings={jobOppenings} */}
      {/* /> */}
    </Layout>
  );
};

export default Careers;
